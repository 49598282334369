
import './App.css';
import BgVideo from './media/bg.mp4'
function App() {
  return (
    <div className="landingpage">

      <video src={BgVideo} autoPlay muted loop className="video-bg" />
      <div className="home-text">
                <h1>Web sajt u pripremi</h1>
                <p>www.tetra.co.rs</p>
            </div>
    </div>
  );
}

export default App;
